import React, { Component } from 'react'
import './NavBar.scss'
import EditSVG from './EditSVG'

export default class NavBar extends Component {
  constructor(props) {
    super(props)
    
    this.state = { 
      scrolling: false,
      resumeHidden: this.props.resumeHidden ,
      colorOpened: false
    }
  }


  componentDidMount() {
    setTimeout(() => {window.scrollTo({ top: 0, behavior: 'smooth' })}, 100)
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  setSelected = event => { this.props.setSelected(event.target.id)}
  handleScroll = async event => {window.scrollY > 45 ? this.setState({scrolling: true}) : this.setState({scrolling: false})}
  openColor = () => {this.setState({colorOpened: !this.state.colorOpened})}
  colorPicker = (e) => this.props.setColor(e.target.getAttribute('id'), e.target.value)
  

  render() {
    return (
      <div className={`navbar f jcb aic outer ${this.state.scrolling === true ? 'scrolled' : ''}`}>
        <div
          className="header f"
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          <p className="m0 ls2 fw6">MITCHELL STEWART</p>
          <p className="m0 ls2">&nbsp;|</p>
          <p className="m0 ls2 fw6 italic">&nbsp;DEVELOPER</p>
        </div>
        <div className="nav-links f">
          <div
            id="about"
            className={`mr05 ls2 link pointer${
              this.props.selected === 'about' ? ' active' : ''
            }`}
            onClick={this.setSelected}
          >
            {`ABOUT`}
          </div>
          <div
            id="projects"
            className={`mr05 ls2 link pointer${
              this.props.selected === 'projects' ? ' active' : ''
            }`}
            onClick={this.setSelected}
          >
            {`PROJECTS`}
          </div>

          <div
            id="contact"
            className={` ls2 link pointer${
              this.props.selected === 'contact' ? ' active' : ''
            }`}
            onClick={this.setSelected}
          >
            {`CONTACT`}
          </div>
          <div
            id="color"
            className={`ls2 nav-color rel pointer${
              this.state.colorOpened ? ' open' : ''
            }`}
            onClick={this.openColor}
          >
            
            <EditSVG fill={this.props.textColor} />
            <div className="color-pickers bggry abs p1 mt1 f jcb">
              <div className="background-picker color-picker f py025 jcb mr1">
                <p className="small-xs pb025 ls1 fw4 pr025">bg: </p>
                <input type="color" defaultValue="#091319" id="background" className="color-slider pointer" onChange={this.colorPicker} />
              </div>
              <div className="text-picker color-picker f py025 jcb">
                <p className="small-xs pb025 ls1 fw4 pr025">txt: </p>
                <input type="color" defaultValue="#faf3ec" id="text" className="color-slider pointer" onChange={this.colorPicker} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
