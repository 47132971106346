import React, { Component } from 'react'
import './Contact.scss'
import medium from './icons/medium.svg'
import github from './icons/github-icon.svg'
import linkedin from './icons/linkedin-icon.png'
import gmail from './icons/gmail-icon.jpg'
import yahoo from './icons/yahoo-icon.png'
import outlook from './icons/outlook-icon.png'
import copy from './icons/copy.png'
export default class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showEmailOptions: false,
      emailCopied: false
    }
    
  }




  handleEmail = () => this.setState({ showEmailOptions: !this.state.showEmailOptions })
  handleCopyEmail = async (e) => {
    this.setState({emailCopied: true})
    try {
      navigator.clipboard.writeText("mitchellwstewart@gmail.com")  
    } catch (error) {
      alert(error)
    }
    document.querySelector('.email-copied').classList.remove('hidden')
    setTimeout (()=> {
      document.querySelector('.email-copied').classList.remove('transparent')
    }, 50)
    setTimeout(
      () => {
        document.querySelector('.email-copied').classList.add('transparent')
        this.setState({emailCopied: false})
      }, 
      3000)
      setTimeout (()=> {
        document.querySelector('.email-copied').classList.add('hidden')
      }, 3500)
  }

  render() {
    return (
      <div className="Contact container--l bggry m2 x p2 rel">
        <div className="copy-container container--s">
          <h2 className="mb025">Contact</h2>
          <div className="f">
          <p className="underline fw5 mb2 mt1 nowrap pointer" style={{height: 'fit-content'}} onClick={this.handleEmail}>Send me a line.</p>
            <ul className= {`email-links ${this.state.showEmailOptions ? "" : "transparent"} f mt0 pl05`}>
              <li className="px05 f fdc jcc">
                <a href="https://mail.google.com/mail/?view=cm&amp;fs=1&amp;to=mitchellwstewart@gmail.com&amp;su=Hey+Mitch+-+Let's+Make+Something!&amp;body=%0A%0AHey+Mitch,+%0A" target="_blank" rel="noopener noreferrer" className="reply-email gmail f jcc">
                  <img src={gmail} alt="gmail"/>
                </a>
              </li>
              <li className="px05 f fdc jcc">
                <a href="http://compose.mail.yahoo.com/?to=mitchellwstewart@gmail.com&amp;subj=Hey+Mitch+-+Let's+Make+Something!&amp;body=Hey+Mitch,+" target="_blank" rel="noopener noreferrer" className="reply-email yahoo f jcc">
                  <img src={yahoo} alt="yahoo"/>
                </a>
              </li>
              <li className="px05 f fdc jcc">
                <a href="https://outlook.live.com/default.aspx?rru=compose&amp;to=mitchellwstewart@gmail.com&amp;subject=Hey+Mitch+-+Let's+Make+Something!&amp;body=%0A%0AHey+Mitch,+%0A" target="_blank" rel="noopener noreferrer" className="reply-email msmail f jcc">
                  <img src={outlook} alt="outlook"/>
                </a>
              </li>
              <li className="px05 f fdc jcc pointer rel">
                <div className="copy-email  f jcc " onClick={this.handleCopyEmail}>
                  <img src={copy} alt="copy address"/>
                  <p className={`email-copied nowrap pl05 f aic small abs bggry transparent hidden`}>Email address copied!</p>
                </div>
              </li>
            </ul>
          </div>
          <p className="lh15 pt05">Let's chat about how I can help with your next project.<br/> Coffee or beer on me.</p>
        </div>

            <div id="links-area">
              <div className="links f mt2 container--xxs jcs">
                <p className="pr2">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://github.com/mitchellwstewart"
                  >
                     <img className="icon" src={github} alt="github" />
                  </a>
                </p>
                <p className="pr2">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/in/mitchellwstewart/"
                  >
                    
                    <img className="icon" src={linkedin} alt="linkedin" />
                  </a>
                </p>
                <p className="pr2">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://medium.com/@mitchellwstewart_70614"
                  >
                     <img className="icon" src={medium} alt="medium" />
                  </a>
                </p>
              </div>
            </div>
          

      </div>
    )
  }
}