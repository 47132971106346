import React, { Component } from 'react'
import './Projects.scss'
const snoweImage = process.env.PUBLIC_URL + '/assets/snowe.jpg'
const toneImage = process.env.PUBLIC_URL + '/assets/tnscr.png'
const synthImage = process.env.PUBLIC_URL + '/assets/synsta.png'

export default class Projects extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hoveredProject: '',
      projectClass: 'project-box backroundImg'
    }
  }


  render() {
    return (
      <div className="Projects container--l bggry m2 x p2">
        <div className="projects-content">
          <div className="page-title">
          <h2 className="mb0">Projects |  Contributions</h2>
          </div>
          <div className="projects f jcb fw">
            <div className="project-item rel my2">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://snowehome.com`}
                id="bppro"
                className={`${this.state.projectClass} bppro`}
              >
                <img src={snoweImage} className="x mb1" alt="snowe" />
                <h3>Snowe</h3>
                <p>Frontend Development</p>
                <p className="italic">While fulltime at Snowe</p>
              </a>
            </div>
            <div className="project-item rel my2">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={'https://github.com/mitchellwstewart/tone-scribble'}
                id="tnscr"
                className={`${this.state.projectClass} tnscr`}
              >
                <img src={toneImage} className="x mb1" alt="tone" />
                <h3>Tone Scribble</h3>
                <p>Full Stack Development</p>
                <p className="italic">Freelance</p>
              </a>
            </div>
            <div className="project-item rel my2">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={'https://github.com/mitchellwstewart/synthstation'}
                id="synsta"
                className={`${this.state.projectClass} synsta`}
              >
                <img src={synthImage} className="x mb1" alt="synth" />
                <h3>Synth Station</h3>
                <p>Frontend Development</p>
                <p className="italic">Freelance</p>
              </a>
            </div>
          </div>
          {/* <div className="section-title">
            <h1 className="underline-2">
              Work
            </h1>
          <div className = "jobs my2">
                <div className="job ">
                  <h3>Snowe</h3>
                  <p>Software Developer</p>
                  <p className="italic">June 2018 - March 2020</p>
                </div>
          </div>

          </div> */}
        </div>
      </div>
    )
  }
}
