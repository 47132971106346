import React, { Component } from 'react';
import ReactGA from 'react-ga'
import './App.scss';
import NavBar from './containers/NavBar/NavBar.jsx';
import About from './containers/About/About.jsx';
import Projects from './containers/Projects/Projects.jsx';
import Contact from './containers/Contact/Contact.jsx';
import Particles from 'react-particles-js';
import { Resume } from './containers/Resume/Resume';
// import Contributions from './containers/Contributions/Contributions.jsx'
// import Resume from './containers/Resume/Resume.jsx'
// import Profile from './containers/Profile/Profile.jsx'
import { withRouter } from 'react-router-dom'
function initializeReactGa() {
  ReactGA.initialize('UA-163606412-1');
  ReactGA.pageview('/homepage')
}


let projectsModule, contactModule, distanceFromTop



class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navClass: 'nav-bar',
      resumeHidden: true,
      selected: 'about',
      backgroundColor: "#091319",
      textColor: "#faf3ec",
      particlesColor: "#faf3ec",
      
    }
   
  }

  componentDidMount () {
    initializeReactGa()
    projectsModule = document.querySelector(`.projects-outer`).getBoundingClientRect()
    contactModule = document.querySelector(`.contact-outer`).getBoundingClientRect()
    distanceFromTop = window.matchMedia('(min-width: 768px)').matches ? 100 : 300
    this.scrollToModule()
    window.addEventListener('scroll', this.scrollToModule)
    
    
  }

  componentDidUpdate (e) {
  }


  componentWillUnmount () {
    
  }

  setSelected = moduleName => {    
    if (moduleName === 'about')
      window.scroll({ top: 0, behavior: 'smooth' })
    if (moduleName === 'projects')
      window.scroll({ top: projectsModule.top - 80, behavior: 'smooth' })
    if (moduleName === 'contact')
      window.scroll({ top: document.body.scrollHeight, behavior: 'smooth' })
  }

  setColor = (element, color) => {
    element === 'background' && this.setState({backgroundColor: color})
    element === 'text' && this.setState({textColor: color})
  }

  scrollToModule = async () => {
    if( window.scrollY >= document.body.scrollHeight - 1000) { 
      this.state.selected !== 'contact' && await this.setState({selected: 'contact'})
    }
    else if( window.scrollY + distanceFromTop >= projectsModule.top && window.scrollY + distanceFromTop < contactModule.top - distanceFromTop ){
      this.state.selected !== 'projects' && await this.setState({selected: 'projects'})
    } 
    else if(window.scrollY + distanceFromTop >= 0 && window.scrollY + distanceFromTop < projectsModule.top){
      this.state.selected !== 'about' && await this.setState({selected: 'about'})
    } 
  }




  toggleResumeHidden = () => this.setState({ resumeHidden: !this.state.resumeHidden })

  render () {
   let params = 
    {
      "particles": {
          "number": {
              "value": `${window.matchMedia("(min-width: 500px)").matches ? 200 : 100}`,
              "density": {
                  "enable": false
              }
          },
          "size": {
              "value": 1.2,
              "random": true
          },
          "line_linked": {
              "enable": false,
          },
          "move": {
              "random": true,
              "speed": 2.15,
              "out_mode": "out"
          },
          "color": {
            "value": `${this.state.textColor}`
          }
      }
  }

  let backgroundColor = {backgroundColor: this.state.backgroundColor}
  let textColor = {color: this.state.textColor}
    return (
      <div className="App" style={backgroundColor}  >
        <div className="particle-outer fill abs y" style={{zIndex: '0'}}><Particles 
params={params}  />
          </div>
        <header className="App-header rel" style={textColor}>
          <div className="navbar-outer fix x z1"><NavBar style={{zIndex: '1'}} setColor={this.setColor} textColor={this.state.textColor} setSelected={this.setSelected} selected={this.state.selected} /></div>
          <div className="about-outer rel x y fill f aic"><About style={{zIndex: '1'}} setSelected={this.setSelected} selected={this.state.selected}/></div>
        </header>
        <main className="rel main">
          <Resume />
        <div className="particle-outer fill abs y" style={{zIndex: '-1'}}><Particles 
params={params} />
          </div>
          <div className="projects-outer outer f jcc"><Projects /></div>
          <div className="contact-outer outer f pb2 jcc"><Contact /></div>
        </main>
      </div>
    );
  }
  
}

export default withRouter(App);
