import React, { Component } from 'react'
import './About.scss'
import Flickity from 'flickity'




export default class About extends Component {

  constructor(props){
    super(props)
    this.scrollToContact = this.scrollToContact.bind(this)
  }
componentDidMount = () => {
  let slider = document.querySelector('.js-slider')
  let flkty = new Flickity( slider, {
  // options
  cellAlign: 'left',
  contain: true,
  prevNextButtons: false,
  pageDots: false,
  wrapAround: true,
  autoPlay: 7000,
  on: {
    change: (index) =>  {
      document.querySelectorAll(".bio-section").item(index).classList.add('init')
    }
  }
});

flkty && document.querySelectorAll('.js-next-section').forEach(nextButton => nextButton.addEventListener('click', ()=> flkty.next()))
}
  scrollToContact = () => {
    this.props.setSelected('contact')
  }

  setSelected = () => {this.props.setSelected('contact')}

  render(){ 
    return(
      <div className="about f jca x outer container-l fdc ais">
        <div className="bio-container-outer x container--5 ">
          <div className="bio-container f js-slider x ">
            <h1 className="mb1 bio-section  container--5 x y f fdc jcc fill">
              <span className="title-word title-word-1">Hi, </span>
              <span className="title-word f aie title-word-2 nowrap">I'm Mitch. <span className="js-next-section mb025 pt1 three first-slide">&rarr;</span></span> 
            </h1>
            <div className="mb1 bio-section  container--5 x f fdc jcc fill">
            <h2 className="title-word title-word-1 mb0">I build websites</h2>
            <h2 className="title-word f aie title-word-2 mb0 nowrap">and digital experiences. <span className="js-next-section pt1 four" >&rarr;</span></h2> 
            

            </div>
            {/* <div className="mb1 bio-section  container--5 x f fdc jcs fill">
              <h3 className="title-word title-word-1 mb0">I grew up in Bangkok and Jakarta. </h3>
              <h3 className="title-word f aie title-word-2 mb0">I'm Canadian. </h3>
              <h3 className="title-word title-word-3 mb075">I record music and audio in my free time.<span className="js-next-section pt1 four">&rarr;</span></h3>
            </div> */}
            <div className="mb1 bio-section  container--5 x f fdc jcc fill">
              <h2  className="title-word title-word-1 mb0">Looking to build something?</h2>
              <h2 className="title-word f aie title-word-2 nowrap">Maybe I can help. <span className="js-next-section pt1 three">&rarr;</span></h2>
            </div>
          </div>
        </div>
        
        <div className="about_blurb container--xxs ">
          <p className="mb0">
          <span className="blurb-word blurb-word-1 lh15 nowrap">NYC based web development </span>
          <br/>
          <span className="blurb-word blurb-word-2 lh15 nowrap">and consultation. Building easy-to-use </span>
          <br/>
          <span className="blurb-word blurb-word-3 lh15 nowrap">and fast things for the internet.</span>
          </p>
          <p className="underline-2 pt1 blurb-word blurb-word-4"><span onClick={this.setSelected}>Let's make something.</span></p>
        </div>
      </div>
    )
  }
}