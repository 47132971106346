import React from "react";


interface Props {
  text: string;
}
export const Resume: React.FC<Props> = () => {
  return (
    <div>
      
      {/* <h1 className="cw">Hello</h1> */}
    </div>
  )
}